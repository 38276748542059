<template>
  <v-dialog
    v-model="isVisible"
    persistent
    max-width="600px"
  >
    <div
      v-if="isVisible"
      class="wrapper"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Add New Twilio Number</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-select
                  v-model="field.phoneType"
                  :items="allPhoneTypes"
                  item-text="label"
                  item-value="value"
                  label="Select Phone Type"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="field.areaCode"
                  required
                  type="number"
                  :rules="[rules.required, rules.counter]"
                  label="Area Code (3 Digit) *"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                class="a-center"
              >
                <v-btn
                  color="primary"
                  depressed
                  :disabled="!(field.phoneType && field.areaCode)"
                  @click="fetchNumbers"
                >
                  Fetch Available Numbers
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="fetchedNumbers.length > 0 && isFetched">
              <v-col
                cols="12"
                class="a-center"
              >
                <h4>Availble Numbers</h4>
              </v-col>
            </v-row>
            <v-row v-if="fetchedNumbers.length === 0 && isFetched">
              <v-col
                cols="12"
                class="a-center"
              >
                <h4>No Numbers Availble</h4>
              </v-col>
            </v-row>
            <v-row v-if="fetchedNumbers.length > 0 && isFetched">
              <v-col
                cols="12"
                class="phoneNumbersWrapper"
              >
                <v-radio-group
                  v-model="field.selectedNumber"
                  column
                >
                  <v-radio
                    v-for="number in fetchedNumbers"
                    :key="number.friendly_name"
                    :label="number.friendly_name"
                    :value="number.phone_number"
                  />
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="actionBar">
          <v-spacer />
          <v-btn
            color="secondary"
            text
            @click="close"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="field.selectedNumber && field.selectedNumber.length>0 && isFetched"
            color="primary"
            text
            @click="submit"
          >
            Add Number
          </v-btn>
        </v-card-actions>
        <v-snackbar
          v-model="snackbar"
        >
          {{ notification }}

          <template v-slot:action="{ attrs }">
            <v-btn
              color="pink"
              text
              v-bind="attrs"
              @click="snackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
  import { VueMaskDirective } from 'v-mask'
  import Vue from 'vue'
  import HttpConnector from '../../../utils/http-connector'

  Vue.directive('mask', VueMaskDirective)

  export default {
    name: 'MessagingServiceAddEditDialog',
    props: {
      isVisible: {
        type: Boolean,
        default: false,
      },
      isEditMode: {
        type: Boolean,
        default: false,
      },
      selectedMessagingService: {
        type: Object,
        default: null,
      },
    },
    data: () => ({
      httpConnector: new HttpConnector(),
      apiEndpoint: process.env.VUE_APP_API_BASE_URL,
      apiCode: process.env.VUE_APP_API_CODE,
      username: null,
      snackbar: false,
      field: {
        phoneType: '',
        areaCode: '',
        selectedNumber: '',
      },
      allPhoneTypes: [{
        label: 'Local',
        value: 1,
      }, {
        label: 'Toll Free',
        value: 2,
      }, {
        label: 'Mobile',
        value: 3,
      }],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 3 || 'Max 3 characters',
      },
      fetchedNumbers: [],
      isFetched: false,
      isFormDirty: false,
    }),

    watch: {
      isVisible (val) {
        if (val) {
          this.isFormDirty = false
          // Dialog opened
          // Reset all fields
          this.field = Object.assign({
            phoneType: '',
            areaCode: '',
            selectedNumber: '',
          })
          this.fetchedNumbers = []
          this.isFetched = false
        } else {
        }
      },
    },

    created () {
      this.username = localStorage.getItem('username')
    },

    methods: {
      async initialize () {
      },

      close () {
        this.$emit('on-close')
      },

      isSearchValid () {
        return !!(this.field.phoneType && this.field.areaCode)
      },

      async fetchNumbers () {
        try {
          this.fetchedNumbers = []
          this.isFetched = false
          this.field.selectedNumber = ''
          this.$emit('on-submit')
          const { data } = await this.httpConnector.makeRequest('get', `${this.apiEndpoint}availabletwiliophonenumbers/${this.field.phoneType}/${this.field.areaCode}?code=${this.apiCode}`)
          this.$emit('on-spinner-hide')
          if (parseInt(this.field.phoneType) === 1) {
            this.fetchedNumbers = data.map(number => ({
              ...number,
              phone_number: number.PhoneNumber,
              friendly_name: number.Name,
            }))
          } else {
            this.fetchedNumbers = data
          }
          this.isFetched = true
        } catch (error) {
          this.$emit('on-spinner-hide')
          this.isFetched = true
        }
      },

      isFormValid () {
        this.notification = ''
        if (!this.field.name || !this.field.name.length) {
          this.notification = 'Fill all the required fields'
          return false
        }
        if (!this.field.number || !this.field.number.length) {
          this.notification = 'Provide a valid 10 digit cell number'
          return false
        }
        return true
      },

      submit () {
        this.isFormDirty = true
        if (!(this.field.selectedNumber && this.field.selectedNumber.length)) {
          this.snackbar = true
          this.notification = 'Please select a phone number'
        } else {
          this.$emit('on-submit')
          const payload = {
            PhoneNumber: this.field.selectedNumber,
          }
          this.httpConnector.makeRequest('post', `${this.apiEndpoint}provisiontwilionumber?code=${this.apiCode}`, payload)
            .then((res) => {
              this.$emit('on-success', res)
              this.close()
            })
            .catch((error) => {
              this.$emit('on-error', error)
            })
        }
      },
    },
  }
</script>

<style scoped>
.a-center {
  text-align: center;
}
.phoneNumbersWrapper {
  max-height: 250px;
  overflow-y: scroll;
}
.actionBar {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 80px;
  padding-bottom: 25px;
  background-color: white;
}
.wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
</style>
